import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { requestSendSignupEmail } from '../../redux/email'
import { Typography, Link, Box } from '@mui/material'
import Html from '../html'

const EndPage = () => {
  const dispatch = useDispatch()
  const project = useSelector((state) => state?.project)
  const auth = useSelector((state) => state?.auth)
  const user = useSelector((state) => state?.user)
  const [countdown, setCountdown] = useState(3)
  const [shouldRedirect, setShouldRedirect] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    dispatch(requestSendSignupEmail(auth))

    if (project?.formRedirectUrl) {
      setShouldRedirect(true)
    }
  }, [auth])

  useEffect(() => {
    if (shouldRedirect) {
      const timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          if (prevCountdown <= 1) {
            clearInterval(timer)
            window.location.href = project.formRedirectUrl
            return 0
          }
          return prevCountdown - 1
        })
      }, 1000)

      return () => clearInterval(timer)
    }
  }, [shouldRedirect, project])

  if (shouldRedirect) {
    return (
      <Box>
        <Typography>
          Skickar dig vidare om {countdown} sekunder, eller
          <Link href={project?.formRedirectUrl} underline="hover">
            klicka här för att fortsätta.
          </Link>
        </Typography>
      </Box>
    )
  }

  return <Html name="signup-complete" data={user} />
}

export default EndPage
